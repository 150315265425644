import { cms, editCMS } from '../helper'
import Tabs from 'react-responsive-tabs'
import { useSelector } from 'react-redux'
import { renderBtnContent } from './actionCall'
import PageWithTabs from './PageVariation/pageWithTabs'

const PageWithTabsNear = ({ page }) => {
  const editMode = useSelector((state) => state.CMS.editMode)
  const cmsData = useSelector((state) => state.cmsData)

  const getTabs = (pages) => {
    const tabs = []

    Object.keys(pages).map((key) => {
      const tab = pages[key]

      // if (!checkPermission('view', pages[key]?.name, null, true)) return
      tabs.push({
        title: cms(tab.cms_header_name),
        cmsTabKey: tab.cms_header_name || key,
        getContent: () => (
          <div style={{ marginTop: '1.9rem' }}>
            <PageWithTabs page={tab} CMSData={cmsData} disableBG={true} grid={true} />
          </div>
        ),
        key: key,
        tabClassName: 'tab',
        panelClassName: 'panel',
      })
    })
    return tabs
  }

  const tabs = {
    [page.name]: page,
    ...page?.tab_near_to_pages,
  }
  const handleTabEditCMS = (e) => {
    const tab = getTabs(tabs)?.filter((tab) => tab.key === e.nextTabKey)

    editCMS(tab?.[0]?.cmsTabKey)
    if (editMode) return false
  }

  if (getTabs(tabs)?.length === 0) return <div style={{ textAlign: 'center' }}>Content not available</div>
  return (
    <Tabs
      tabsWrapperClass={'body-tabs body-tabs-alt'}
      transform={false}
      showInkBar={true}
      items={getTabs(tabs)}
      beforeChange={(e) => handleTabEditCMS(e)}
    />
  )
}

export default PageWithTabsNear
