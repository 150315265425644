import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useSelector } from 'react-redux'

const DomainFilter = ({ value, updateValue, triggerSearch, column, handleSubmit }) => {
  const coreData = useSelector((state) => state.CMS.coreData)
  return (
    <AvForm
      onChange={(e) => {
        updateValue(e.target.value)
        triggerSearch(e.target.value)
      }}>
      <AvField type='select' name='domain'>
        <option value=''>Select</option>
        {coreData.domains
          ?.filter((domain) => domain.selected)
          ?.map((option, key) => {
            return (
              <option value={option.domain_id} key={key}>
                {option.domain_name}
              </option>
            )
          })}
      </AvField>
    </AvForm>
  )
}

export default DomainFilter
