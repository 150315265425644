import React, { useEffect, useState } from 'react'
import { useTable, useRowSelect, useSortBy, useFilters, useGlobalFilter, useExpanded } from 'react-table'
import { matchSorter } from 'match-sorter'
import cx from 'classnames'
import {
  can,
  checkQuestionnaireCompleted,
  cms,
  editCMS,
  getFromRedux,
  getSyncRowIdFromRedux,
  isEqual,
  renderBlockColorClass,
  renderTextColorClass,
  renderBlockStyle,
  renderBlockColorStyle,
  checkIsDate,
  checkIsDateInFuture,
  checkIsDateInPast,
} from '../../helper'
import { Button, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { connect, useDispatch, useSelector } from 'react-redux'
import { InlineEdit } from './inlineEdit'
import { tidyName } from '../../helper'
import { clearTableDropDown } from '../../Redux/Reducers/CMS'
import Uploads from './Component/uploads'
import moment from 'moment'
import DateTimeTableFilter from './Component/Filter/dateTime'
import AggregateFilter from './Component/Filter/AggregateFilter/aggregateFIlter'
import QR from './Component/QR/QR'
import CMSSelect from '../Forms/Fields/select'
import { AvForm } from 'availity-reactstrap-validation'
import DomainFilter from './Component/Filter/AggregateFilter/domainFilter'

function dateDiffInMin(a, b) {
  const _MS_PER_DAY = 1000 * 60
  return Math.floor((a - b) / _MS_PER_DAY)
}

const compareDateCondition = (comparision, row_value, value) => {
  switch (comparision) {
    case 'greater_than':
      if (row_value > value) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (row_value < value) {
        return true
      } else {
        return false
      }
    case 'equals':
      if (value === row_value) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}

const compareCondition = (comparision, row_value, value) => {
  switch (comparision) {
    case 'greater_than':
      if (row_value > value) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (row_value < value) {
        return true
      } else {
        return false
      }
    case 'equals':
      if (value === row_value) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}

const checkStartedNotEqualCondition = (condition, row) => {
  if (!condition?.method_name) {
    if (!row['start_date']) {
      return true
    }
    if (row['start_date']) {
      return checkIsDateInFuture(row['start_date'])
    }
  } else {
    const arr = row[condition?.method_name]?.map((contractor) => {
      if (!contractor['start_date']) {
        return true
      }
      if (contractor['start_date']) {
        return checkIsDateInFuture(contractor['start_date'])
      }
    })
    let a = arr?.filter((item) => !item)
    if (a?.length === 0) {
      return true
    } else {
      return false
    }
  }
}

const checkStartedEqualCondition = (condition, row) => {
  if (!condition?.method_name) {
    if (!row['start_date']) {
      return false
    }
    let started, terminated

    if (row['start_date']) {
      let value = checkIsDateInFuture(row['start_date'])
      started = !value
    }
    let endDate = row['termination_date'] || row['end_date']
    if (endDate) {
      let value = checkIsDateInFuture(endDate)

      terminated = !value
    } else {
      return started
    }
    let value = started && terminated

    return !value
  } else {
    console.log('row::::::::::::::', row)
    if (row[condition?.method_name]?.length === 0) {
      return false
    }
    const arr = row[condition?.method_name]?.map((contractor) => {
      if (!contractor['start_date']) {
        return false
      }
      let started, terminated

      if (row['start_date']) {
        let value = checkIsDateInFuture(contractor['start_date'])
        started = !value
      }
      let endDate = contractor['termination_date'] || contractor['end_date']
      if (endDate) {
        let value = checkIsDateInFuture(endDate)

        terminated = !value
      } else {
        return started
      }
      let value = started && terminated
      return value
    })
    let a = arr?.filter((item) => item)
    if (a?.length >= 0) {
      return true
    } else {
      return false
    }
  }
}

const checkDatePassedCondition = (condition, row) => {
  if (condition?.method_name) {
    const arr = row[condition?.method_name]?.map((contractor) => {
      let isDatePassed = false
      if (contractor['termination_date'] || contractor['end_date']) {
        const date = contractor['termination_date'] || contractor['end_date']
        isDatePassed = checkIsDateInPast(date)
      }
      return isDatePassed
    })
    let a = arr?.filter((item) => !item)
    if (a?.length === 0) {
      return true
    } else {
      return false
    }
  } else {
    let isDatePassed = false
    if (row['termination_date'] || row['end_date']) {
      const date = row['termination_date'] || row['end_date']
      isDatePassed = checkIsDateInPast(date)
    }
    return isDatePassed
  }
}

const checkSingleCondition = (condition, row) => {
  switch (condition.type) {
    case 'todays_date':
      const current_date = new Date()
      const column = condition?.db_field_name?.split('.')[1] || condition?.db_field_name
      // const column = condition?.db_field_name?.split('.')[1]

      const r_value = row[`${column}_label`] || row[column]
      const row_value = r_value && checkIsDate(r_value) && new Date(r_value)

      if (!row_value) return false
      // row_value?.setHours(0, 0, 0, 0)
      // current_date?.setHours(0, 0, 0, 0)

      return compareCondition(condition?.condition, row_value, current_date)
    case 'fixed_date_value':
      const date_value = condition?.date_value?.length > 0 ? new Date(condition?.date_value) : null
      const column_for_fixed_date = condition?.db_field_name?.split('.')[1] || condition?.db_field_name
      const row_date_value = row[column_for_fixed_date] && row[column_for_fixed_date]?.length > 0 ? new Date(row[column_for_fixed_date]) : null

      if (row_date_value === null) {
        return false
      }

      return compareCondition(condition?.condition, row_date_value, date_value)

    case 'dynamic':
      const value = isNaN(condition?.dynamic_value) ? condition?.dynamic_value : condition?.dynamic_value?.toString()
      const column_for_dynamic = condition?.db_field_name?.split('.')[1] || condition?.db_field_name
      const row_value_dynamic = isNaN(row[column_for_dynamic]) ? row[column_for_dynamic] : parseInt(row[column_for_dynamic])
      return compareCondition(condition?.condition, row_value_dynamic?.toString(), value)
    case 'fixed_numeric_value':
      const value_numeric = isNaN(condition?.numeric_value) ? parseInt(condition?.numeric_value) : condition?.numeric_value
      const column_for_numeric = condition?.db_field_name?.split('.')[1] || condition?.db_field_name
      const row_value_numeric = isNaN(row[column_for_numeric]) ? parseInt(row[column_for_numeric]) : row[column_for_numeric]

      if (row_value_numeric === NaN || row_value_numeric === null) {
        return false
      }
      return compareCondition(condition?.condition, row_value_numeric, value_numeric)
    case 'between_from_current_date_time':
      const todays_date = new Date()
      const row_field_value =
        row[condition?.db_field_name] && row[condition?.db_field_name] !== '0000-00-00' && new Date(row[condition?.db_field_name])
      const diffTime = row_field_value - todays_date
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (condition?.numeric_value) {
        if (diffDays <= parseInt(condition?.numeric_value) && diffDays > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    case 'where_null':
      if (row[condition.db_field_name] === null) {
        return true
      }
    case 'where_not_null':
      if (row[condition.db_field_name] !== null) {
        return true
      }
    case 'all_uploaded_documents':
      if (row.upload_document_id_label && row.upload_document_id_label.lastIndexOf(' (Required)') > -1) {
        if (row.upload_document?.has_expiry) {
          if (row.valid_too && moment(row.valid_too).isAfter()) {
            return true
          } else {
            return false
          }
        } else {
          if (row.upload_files?.length > 0) {
            return true
          } else {
            return false
          }
        }
      }
    case 'select_multiple_values':
      const values = condition?.multiple_values?.map((item) => item.toLowerCase())

      if (
        values?.includes(row?.[condition?.db_field_name]?.toString()?.toLowerCase()) ||
        values?.includes(row?.[`${condition?.db_field_name}_label`]?.toString()?.toLowerCase())
      ) {
        return true
      } else {
        return false
      }
    case 'date_passed':
      let isDatePassed = false
      let where_or
      if (condition?.where_or && condition?.where_or?.length > 0) {
        where_or = condition?.where_or?.map((cond) => {
          return checkSingleCondition(cond, row)
        })
      }
      if (condition?.condition === 'equals') {
        isDatePassed = checkDatePassedCondition(condition, row)
      }
      if (where_or) {
        let a = where_or?.filter((item) => item)
        if (a.length > 0) {
          isDatePassed = true
        }
      }
      return isDatePassed

    case 'started':
      let isSatisfied = false
      let where_or_condition
      if (condition?.where_or && condition?.where_or?.length > 0) {
        where_or_condition = condition?.where_or?.map((cond) => {
          return checkSingleCondition(cond, row)
        })
      }
      if (condition?.condition === 'not_equal_to') {
        isSatisfied = checkStartedNotEqualCondition(condition, row)
      }
      if (condition?.condition === 'equals') {
        isSatisfied = checkStartedEqualCondition(condition, row)
      }
      if (where_or_condition) {
        let a = where_or_condition?.filter((item) => item)
        if (a.length > 0) {
          isSatisfied = true
        }
      }
      return isSatisfied

    case 'not_has':
      if (row[condition?.method_name] && row[condition?.method_name]?.length === 0) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}

const checkAllConditions = (conditions, row) => {
  if (conditions?.length === 0) {
    return false
  }
  const all_condition_satisfied = conditions?.map((condition) => {
    return checkSingleCondition(condition, row)
  })

  if (all_condition_satisfied?.includes(false)) {
    return false
  } else {
    return true
  }
}

const checkRowColor = (grid_colors, row) => {
  let final_color = []
  grid_colors?.forEach((color) => {
    const color_satisfied = checkAllConditions(color?.conditions, row)

    if (color_satisfied) {
      final_color.push(color?.color)
    }
  })

  if (row?.completed_at && row?.completed_at?.length > 0) {
    if (dateDiffInMin(new Date().getUTCDate(), new Date(row?.completed_at)) < 5) {
      return 'success'
    }
  }
  if (final_color.length > 0) {
    return final_color[0]
  }
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type='checkbox' ref={resolvedRef} {...rest} />
    </>
  )
})

// Define a default UI for filtering
function DefaultColumnFilter({ column }) {
  const { filterValue, preFilteredRows, setFilter } = column
  const count = preFilteredRows.length

  const handleSubmit = (e) => {
    e.preventDefault()
    setFilter(e.target.search.value || undefined)
  }

  return (
    <div className='form-group'>
      <form onSubmit={handleSubmit}>
        <input name='search' className='form-control' defaultValue={filterValue || ''} placeholder={`search..`} />
      </form>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}>
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type='range'
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}>
      <input
        value={filterValue[0] || ''}
        type='number'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type='number'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

function FilterBox({ filterValue, triggerSearch, refreshGrid, clearFilters, column }) {
  const [value, updateValue] = useState(filterValue)
  const [inputLabel, updateInputLabel] = useState(filterValue)

  const handleSubmit = (e) => {
    e.preventDefault()
    triggerSearch(e.target.search.value || undefined)
  }
  useEffect(() => {
    if (clearFilters) {
      updateValue()
    }
  }, [refreshGrid, clearFilters])

  useEffect(() => {
    const fields = ['datetime', 'date', 'switch', 'multi_radio', 'radio', 'dropdown']
    if (fields?.includes(column?.colData?.field_setting?.field) || column?.filter === 'aggregate_filter') return

    const timer = setTimeout(() => {
      if (value !== filterValue) {
        // triggerSearch(value || undefined)
      }
    }, 2000)

    return () => clearTimeout(timer)
  }, [value])

  if (column?.colData?.field_id === 'qr') return

  if (column?.filter === 'aggregate_filter') {
    return <AggregateFilter value={value} updateValue={updateValue} triggerSearch={triggerSearch} column={column} handleSubmit={handleSubmit} />
  }
  if (column?.colData?.field_id === 'domain_id') {
    return <DomainFilter value={value} updateValue={updateValue} triggerSearch={triggerSearch} column={column} handleSubmit={handleSubmit} />
  }
  switch (column?.colData?.field_setting?.field) {
    case 'datetime':
    case 'date':
      return (
        <DateTimeTableFilter value={value} updateValue={updateValue} triggerSearch={triggerSearch} type={column?.colData?.field_setting?.field} />
      )
    case 'switch':
      return (
        <div
          className='switch has-switch '
          style={{ marginBottom: '-5px' }}
          data-on-label='ON'
          data-off-label='OFF'
          onClick={(e) => {
            updateValue(!value)
            triggerSearch(!value)
          }}>
          <div
            className={cx('switch-animate', {
              'switch-on': value,
              'switch-off': !value,
            })}>
            <input type='checkbox' />
            <span className='switch-left bg-info'>ON</span>
            <label>&nbsp;</label>
            <span className='switch-right bg-info'>OFF</span>
          </div>
        </div>
      )
    case 'multi_radio':
    case 'radio':
    case 'dropdown':
      const itemdata = {
        ...column?.colData,
        ...column?.colData?.field_setting,
      }

      return (
        <AvForm className='tableFilter'>
          <CMSSelect
            data={itemdata}
            id={column?.colData?.field_id}
            isTable={true}
            classNamePrefix='select'
            value={value}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            onChangeAction={(a, b, c, value, label, isInitialUpdate) => {
              if (isInitialUpdate) return
              updateValue(value)

              // handleNewValueSubmit(value, false, label)
              updateInputLabel(label)
              triggerSearch(value)
            }}
            // index={index}
            // inlineEditActive={inlineEditActive}
            inputLabel={inputLabel}
            // id={id}
            // data={data}
            // toggleActions={toggleActions}
            // fieldKey={fieldKey}
            // updateValues={() => updateValues()}
            // updateKeysForReduxData={(keys, key) => updateKeysForReduxData(keys, key)}
            // onChangeAction={(data, action, field, value) => fieldAction(data, action, field, value)}
          />
        </AvForm>
      )

    default:
      return (
        <div className='form-group'>
          <form onSubmit={handleSubmit}>
            <input
              name='search'
              className='form-control '
              value={value || ''}
              onChange={(e) => updateValue(e.target.value)}
              placeholder={`search..`}
            />
          </form>
        </div>
      )
  }
}
const select = (state) => ({
  editMode: state.CMS.editMode,
})

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <input value={value} onChange={onChange} onBlur={onBlur} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

function Table({
  sourceTable = null,
  loadTable,
  columns = [],
  data = [],
  pagination = {},
  sortBy = [],
  updateMyData,
  filters = [],
  renderRowSubComponent = () => {},
  setting = {},
  meta = {},
  initialPayload = {},
  links = {},
  editMode,
  search = () => {},
  selectedRows = () => [],
  sortColumn = () => {},
  getByUrl = () => {},
  changeRowsPerPage = () => {},
  refreshGrid = false,
  clearFilters = false,
  mainGrid,
  inlineEditActive,
  groupRow,
  pageName,
  parentPageName,
  isModel,
  expandAllRow,
  recPerPage,
  data_validations,
}) {
  const [sort, updateSort] = useState(sortBy)
  const [currentInput, updateCurrentPageInput] = useState(meta.current_page)
  const [rowsPerPage, updateRowsPerPage] = useState(recPerPage?.value)
  const dispatch = useDispatch()

  const primaryColor = useSelector((theme) => theme.ThemeOptions.primaryColor)
  const grid_colors = mainGrid?.grid_colors

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  useEffect(() => {
    dispatch(clearTableDropDown())
  }, [])

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, toggleAllRowsExpanded, prepareRow, visibleColumns, selectedFlatRows, state } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: sort,
          filters: filters,
          expanded: true,
        },
        updateMyData,
        defaultColumn, // Be sure to pass the defaultColumn option
        // filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => {
          return [
            // Let's make a column for selection
            {
              commonStyle: {
                width: '4%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 'none',
                padding: '15px 2px',
              },
              id: 'selection',
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: (props) => {
                return (
                  setting.select_all_records && (
                    <div style={{ textAlign: 'center' }}>
                      <IndeterminateCheckbox {...props.getToggleAllRowsSelectedProps()} />
                    </div>
                  )
                )
              },
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                )
              },
            },
            ...columns,
          ]
        })
      }
    )

  useEffect(() => toggleAllRowsExpanded(expandAllRow), [expandAllRow])

  useEffect(() => {
    updateCurrentPageInput(meta.current_page)
    if (!isEqual(state.sortBy, sort)) {
      updateSort(state.sortBy)
      if (!editMode) {
        sortColumn(state.sortBy)
      }
    }
    if (refreshGrid || clearFilters) {
      updateSort(sortBy)
    }
    selectedRows(selectedFlatRows)
  }, [state, editMode, meta, selectedFlatRows])
  // const linkClassName = primaryColor?.split(' ')[0] + '-text'
  const linkClassName = renderTextColorClass(primaryColor)?.split(' ')[0] + '-text'

  let group_by_setting
  if (mainGrid?.group_by_setting) {
    group_by_setting = JSON.parse(mainGrid?.group_by_setting)
  }

  let row_group = []
  const is_group_open = group_by_setting?.Column && groupRow ? true : false

  const checkRowGroupExist = (group, key) => {
    let exist = false
    for (let i = 0; i < group?.length; i++) {
      if (Object.keys(group[i])[0] === key?.toString()) {
        exist = true
        break
      }
    }
    return exist
  }

  const getExistingRowGroupIndex = (group, key) => {
    let index
    for (let i = 0; i < group?.length; i++) {
      if (Object.keys(group[i])[0] === key?.toString()) {
        index = i
        break
      }
    }
    return index
  }

  if (is_group_open) {
    rows?.map((row) => {
      if (checkRowGroupExist(row_group, row?.original[group_by_setting?.Column])) {
        const index = getExistingRowGroupIndex(row_group, row?.original[group_by_setting?.Column])
        let existing_object = row_group[index]
        existing_object[row?.original[group_by_setting?.Column]] = [...existing_object[row?.original[group_by_setting?.Column]], row]
      } else {
        const key = row?.original[group_by_setting?.Column]
        let new_group = {}
        new_group[key] = [row]
        row_group?.push(new_group)
      }
    })
  } else {
    row_group.push({
      'no-group': rows,
    })
  }

  const tableOptions = useSelector((state) => state.CMS.tableDropDown)

  const getGroupTitle = (group) => {
    let title = group
    if (tableOptions[group_by_setting?.Column]) {
      const option = tableOptions[group_by_setting?.Column]?.filter((option) => option.value === group)

      if (option?.length > 0) {
        title = option[0]?.label
      }
    } else {
      title = group
    }

    return title
  }

  const getGroupString = (text, group) => {
    const old_string = text
    const i1 = text.indexOf('{')
    const i2 = text.indexOf('}')
    // const id = text.slice(i1 + 1, i2)
    const ans = []
    let id = ''

    for (let i = 0; i < old_string.length; i++) {
      if (old_string[i - 1] === '{') {
        id = id + old_string[i]
      } else if (old_string[i + 1] === '}') {
        id = id + old_string[i]
        ans.push(id)
        id = ''
      } else if (id.length > 0) {
        id = id + old_string[i]
      }
    }

    const old_dynamic_string = old_string?.slice(old_string?.indexOf('{'), old_string?.lastIndexOf('}') + 1)

    let new_dynamic_string

    let new_string, new_value
    //ans = ['name','client_zip']

    group?.map((row, index) => {
      let new_temp_dynamic_string = old_dynamic_string

      ans?.map((id) => {
        if (tableOptions[id]) {
          const selectedOption = tableOptions[id]?.filter((option) => option?.value === row?.original[id])
          if (selectedOption && selectedOption?.length > 0) {
            new_temp_dynamic_string = new_temp_dynamic_string?.replace(`{${id}}`, selectedOption[0]?.label)
          } else {
            new_temp_dynamic_string = new_temp_dynamic_string?.replace(`{${id}}`, '')
          }
        } else {
          new_temp_dynamic_string = new_temp_dynamic_string?.replace(`{${id}}`, row?.original[id])
        }
      })

      if (index === 0) {
        new_dynamic_string = new_temp_dynamic_string
      } else {
        new_dynamic_string = new_dynamic_string + ', ' + new_temp_dynamic_string
      }
    })

    new_string = old_string?.replace(old_dynamic_string, new_dynamic_string)

    return new_string
  }

  const syncRowId = getSyncRowIdFromRedux(parentPageName, pageName)

  return (
    <div className='ReactTable -striped -highlight mb-5'>
      <table {...getTableProps()} className='rt-table'>
        {headerGroups.map((headerGroup, key) => {
          return (
            <thead key={key} className='rt-thead -header'>
              <tr {...headerGroup.getHeaderGroupProps()} className='rt-tr'>
                {headerGroup.headers.map((column, key) => {
                  if (column.commonStyle && column.commonStyle.editable) {
                    return (
                      <th
                        {...column.getHeaderProps(setting.sort_all_columns ? column.getSortByToggleProps() : {})}
                        key={key}
                        style={{ ...column.commonStyle, borderBottom: 'none' }}
                        className={
                          column.isSorted && setting.sort_all_columns
                            ? column.isSortedDesc
                              ? 'rt-th  -sort-desc -cursor-pointer'
                              : 'rt-th  -sort-asc -cursor-pointer'
                            : 'rt-th'
                        }></th>
                    )
                  }
                  return (
                    <th
                      {...column.getHeaderProps(setting.sort_all_columns ? column.getSortByToggleProps() : {})}
                      key={key}
                      style={{ ...column.commonStyle, overflow: 'hidden', display: 'flex' }}
                      className={
                        column.isSorted && setting.sort_all_columns
                          ? column.isSortedDesc
                            ? 'rt-th  -sort-desc -cursor-pointer'
                            : 'rt-th  -sort-asc -cursor-pointer'
                          : 'rt-th'
                      }>
                      {column.render('Header')}
                    </th>
                  )
                })}
              </tr>
              <tr {...headerGroup.getHeaderGroupProps()} key={key + 1} className='rt-tr'>
                {headerGroup.headers.map((column, key) => (
                  <th key={key} style={column.commonStyle} className='rt-th '>
                    {column.filter && setting.filiter_all_columns ? (
                      <div className={'table-column-search'}>
                        <FilterBox
                          filterValue={column.savedFilterValue}
                          refreshGrid={refreshGrid}
                          clearFilters={clearFilters}
                          triggerSearch={(text) => {
                            search({ ['-filter-' + column.id]: text || '' }, sort)
                          }}
                          column={column}
                        />
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
          )
        })}

        <tbody {...getTableBodyProps()} className='rt-tr-group' style={{ minHeight: '10rem' }}>
          {row_group?.map((row_group, index) => {
            return (
              <React.Fragment key={index}>
                {is_group_open && (
                  <tr style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ margin: '0.5rem' }}>
                      {' '}
                      <b>{getGroupTitle(row_group[Object.keys(row_group)[0]][0]?.original[group_by_setting?.Column])}</b> &nbsp;
                      {getGroupString(group_by_setting?.text, row_group[Object.keys(row_group)[0]])} {}
                    </p>
                  </tr>
                )}

                {row_group[Object.keys(row_group)[0]].map((row, i) => {
                  prepareRow(row)
                  const color = row.original.row_color || ''
                  const row_color = checkRowColor(grid_colors, row.original)

                  return (
                    <RenderRow
                      key={i}
                      i={i}
                      row_color={row_color}
                      color={color}
                      syncRowId={syncRowId}
                      row={row}
                      sourceTable={sourceTable}
                      loadTable={loadTable}
                      inlineEditActive={inlineEditActive}
                      pageName={pageName}
                      parentPageName={parentPageName}
                      isModel={isModel}
                      visibleColumns={visibleColumns}
                      editMode={editMode}
                      renderRowSubComponent={renderRowSubComponent}
                      mainGrid={mainGrid}
                      data_validations={data_validations}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      {pagination && (
        <div className='pagination-bottom'>
          <div className='-pagination'>
            <div className='-previous'></div>
            {pagination.page_control && (
              <div className='-center'>
                <span onClick={() => editCMS(setting.page_before_cms_value)}>{cms(setting.page_before_cms_value)}</span>
                <Pagination className='mt-3 mr-2 ml-2'>
                  {meta?.links?.map((item, key) => {
                    return (
                      <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                        <PaginationLink
                          first
                          onClick={() => getByUrl(item.url)}
                          className={item.active ? renderBlockColorClass(primaryColor) : linkClassName}
                          style={item.active ? { ...renderBlockStyle(primaryColor) } : { ...renderBlockColorStyle(primaryColor) }}>
                          {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                        </PaginationLink>
                      </PaginationItem>
                    )
                  })}
                </Pagination>
                {getFromRedux(pagination.redux_name) && (
                  <>
                    <span onClick={() => editCMS(setting.page_after_cms_value)}>{cms(setting.page_after_cms_value)}</span>
                    <span className='m-2'>{meta.last_page}</span>
                    <span
                      onClick={() => editCMS(setting.page_end_cms_value)}
                      className='pl-2'
                      style={{ borderLeft: '1px solid #00000066', padding: '0.7rem' }}>
                      {cms(setting.page_end_cms_value)}
                    </span>
                    <span className='select-wrap -pageSizeOptions'>
                      <select
                        aria-label='rows per page'
                        selected={rowsPerPage}
                        value={rowsPerPage}
                        onChange={(e) => {
                          changeRowsPerPage(e.target.value)
                          updateRowsPerPage(e.target.value)
                        }}>
                        {getFromRedux(pagination.redux_name).map((item, key) => {
                          return (
                            <option key={key} value={item.value}>
                              {item.value} Rows
                            </option>
                          )
                        })}
                      </select>
                    </span>
                  </>
                )}
              </div>
            )}
            <div className='-next'></div>
          </div>
        </div>
      )}
    </div>
  )
}

const RenderRow = ({
  i,
  row_color,
  color,
  syncRowId,
  row,
  sourceTable,
  loadTable,
  inlineEditActive,
  pageName,
  parentPageName,
  isModel,
  visibleColumns,
  editMode,
  renderRowSubComponent,
  mainGrid,
  data_validations,
}) => {
  const [isUpdatedRow, setIsUpdatedRow] = useState(false)
  const [isQuestionnaireCompletedRow, setIsQuestionnaireCompletedRow] = useState(false)
  const rowOriginal = row?.original
  const completedQuestionnaireRow = useSelector((state) => state.CMS.completedQuestionnaireRow)

  useEffect(() => {
    const currentDate = new Date()
    var offset = currentDate.getTimezoneOffset()
    const utcCurrentTime = new Date(currentDate.getTime() + offset * 60000)
    const time = rowOriginal?.updated_at || rowOriginal?.created_at
    const updatedTime = time && new Date(time)

    if (currentDate - updatedTime < 1000 * 30) {
      setIsUpdatedRow(true)
    } else {
      setIsUpdatedRow(false)
    }
  }, [rowOriginal?.updated_at, rowOriginal?.created_at, rowOriginal?.id])

  useEffect(() => {
    if (checkQuestionnaireCompleted(mainGrid?.id, rowOriginal?.id)) {
      setIsQuestionnaireCompletedRow(true)
    } else {
      setIsQuestionnaireCompletedRow(false)
    }
  }, [completedQuestionnaireRow])

  useEffect(() => {
    if (isUpdatedRow) {
      setTimeout(() => {
        setIsUpdatedRow(false)
      }, 10000)
    }
  }, [isUpdatedRow])

  return (
    <React.Fragment key={i}>
      <tr
        {...row.getRowProps()}
        className={
          i % 2 == 0
            ? 'rt-tr -odd bg-' +
              color +
              `${row_color ? `${row_color}` : ''}` +
              `${row_color ? ' sidebar-text-light' : ''}` +
              `${syncRowId?.includes(row.original.id) || !row.original.id ? ` blue-border` : ''}` +
              `${isUpdatedRow ? ` green-border` : isQuestionnaireCompletedRow ? ' green-border' : ''}`
            : 'rt-tr -even bg-' +
              color +
              `${row_color ? `${row_color}` : ''}` +
              `${row_color ? ' sidebar-text-light' : ''}` +
              `${syncRowId?.includes(row.original.id) || !row.original.id ? ` blue-border` : ''}` +
              `${isUpdatedRow ? ` green-border` : isQuestionnaireCompletedRow ? ' green-border' : ''}`
        }>
        {row.cells.map((cell, key) => {
          if (cell.column.colData && cell.column.colData.component_name === 'text_data_col' && cell.column.colData?.field_id === 'uploads') {
            return (
              <td {...cell.getCellProps()} style={{ ...cell.column.commonStyle }} key={key} className={'rt-td bg-' + cell.background_color}>
                <Uploads data={cell?.value} />
              </td>
            )
          }
          if (
            cell.column.colData &&
            cell.column.colData.component_name === 'text_data_col' &&
            (cell.column.colData?.field_id === 'qr' || cell.column.colData?.field_id === 'photo')
          ) {
            return (
              <td {...cell.getCellProps()} style={{ ...cell.column.commonStyle }} key={key} className={'rt-td bg-' + cell.background_color}>
                <QR data={cell?.value} />
              </td>
            )
          }
          if (cell.column.colData && cell.column.colData.component_name === 'text_data_col') {
            return (
              <InlineEdit
                key={key}
                cell={cell}
                sourceTable={sourceTable}
                loadTable={loadTable}
                field={cell.column?.colData?.field_setting}
                item={cell.column?.colData}
                inlineEditActive={inlineEditActive}
                isEditable={cell.column.editable}
                rowId={row?.original?.id}
                pageName={pageName}
                parentPageName={parentPageName}
                isModel={isModel}
                data_validations={data_validations}
              />
            )
          }
          return (
            <td {...cell.getCellProps()} style={{ ...cell.column.commonStyle }} key={key} className={'rt-td bg-' + cell.background_color}>
              <div style={{ overflow: 'auto', maxHeight: '100%', wordWrap: 'break-word' }}>{cell.render('Cell')}</div>
            </td>
          )
        })}
      </tr>
      {row.isExpanded ? (
        <tr className={i % 2 == 0 ? 'rt-tr -padRow bg-' + color : 'rt-tr -padRow bg-' + color}>
          <td colSpan={visibleColumns.length} style={{ width: '100%' }}>
            {renderRowSubComponent(row.original, editMode)}
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  )
}

export default connect(select)(Table)
