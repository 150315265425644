import { Fragment, useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { triggerBase64Download } from 'common-base64-downloader-react'

import { covertFileIntoBase64 } from '../../../helper'
import RedactorX from '../../../Vendor/redactorx/redactorx'
import FileUpload from './fileUpload'

const fileTypes = ['PDF', 'EPS', 'JPG', 'JPEG', 'TIFF', 'GIF', 'PNG', 'ZIP']

const PreviewFile = ({ file, onDownLoadFile }) => {
  const fileType = file.slice(file?.indexOf('/') + 1, file?.indexOf(';'))

  if (fileType === 'pdf') {
    return <div>Pdf</div>
  } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
    return (
      <>
        <img src={file} height={100} width='100%'></img>
        <span
          onClick={() => {
            onDownLoadFile()
          }}
          style={{ cursor: 'pointer', color: 'blue' }}>
          Click here to download File
        </span>
      </>
    )
  }
}

function CommonFields({ item, selectedAnswer = null, isArray = false, answers = [], setAnswers, current_answer_object = {}, requiredError }) {
  const [form, setForm] = useState({
    comment: '',
    file: '',
  })
  const [allowFields, setAllowFields] = useState({
    comment: false,
  })

  const [file, setFile] = useState()

  useEffect(() => {
    let comment = false
    if (selectedAnswer && item.item_answers) {
      item.item_answers.map((i) => {
        if (isArray) {
          const find = selectedAnswer.find((answer) => i.id === answer)
          if (find && i.add_comment) {
            comment = true
          }
        } else {
          if (i.id == selectedAnswer && i.add_comment) {
            comment = true
          }
        }
      })
    } else {
      comment = false
    }
    setAllowFields({ ...allowFields, comment })
  }, [selectedAnswer])

  const onChangeComment = (c) => {
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          comment: c,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    RedactorX('#comment' + item?.id, {
      subscribe: {
        'editor.change': function (event) {
          // setForm({ ...form, comment: event.params.html })
          onChangeComment(event.params.html)
        },
      },
    })
  })

  const handleChange = (file) => {
    covertFileIntoBase64(file).then((data) => {
      onFileChange(data)
    })
  }
  const onDownLoadFile = () => {
    triggerBase64Download(file, (Math.random() + 1).toString(36).substring(7))
  }

  const onRemoveFile = () => {
    onFileChange(undefined)
  }

  const onFileChange = (data) => {
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          upload_base64: data,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    // setForm({...form,comment:current_answer_object?.comment})
  }, [JSON.stringify(current_answer_object)])

  useEffect(() => {
    setFile(current_answer_object?.upload_base64)
    setForm({ ...form, comment: current_answer_object?.comment })
  }, [JSON.stringify(current_answer_object)])
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const fileType = file?.slice(file?.indexOf('/') + 1, file?.indexOf(';'))
  let fileIsImage
  if (file && fileType && (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png')) {
    fileIsImage = true
  } else {
    fileIsImage = false
  }

  return (
    <Fragment>
      <div>
        {/* item.has_upload */}
        {allowFields.comment && (
          <FormGroup>
            <Label for='exampleAddress'>Comment</Label>
            <textarea id={'comment' + item?.id} style={{ width: '100%' }} defaultValue={form.comment}></textarea>
          </FormGroup>
        )}
        {console.log('comment required:::::', allowFields.comment)}
        {allowFields.comment && current_answer_object?.add_comment && requiredError && !current_answer_object?.comment?.replace(/<[^>]+>/g, '') && (
          <div style={{ color: 'red' }}>Please Add Comment</div>
        )}
        {(item?.has_upload === 1 || item?.has_upload === true) && (
          <div style={{ marginTop: '1rem' }}>
            <FileUploader handleChange={handleChange} name='file' types={fileTypes} multiple={false} fileOrFiles={file} />
            {file && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span
                  onClick={() => {
                    if (fileIsImage) {
                      setPreviewModalOpen(!previewModalOpen)
                    } else {
                      onDownLoadFile()
                    }
                  }}
                  style={{ cursor: 'pointer' }}>
                  {fileIsImage ? 'Preview' : 'Click here to download'}
                </span>
                <span
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => {
                    onRemoveFile()
                  }}>
                  Remove File
                </span>
                <Modal isOpen={previewModalOpen} toggle={() => setPreviewModalOpen(!previewModalOpen)} style={{ width: 500 }}>
                  <ModalHeader toggle={() => setPreviewModalOpen(!previewModalOpen)}>Preview</ModalHeader>
                  <ModalBody>
                    <PreviewFile file={file} onDownLoadFile={onDownLoadFile} />
                  </ModalBody>
                </Modal>
              </div>
            )}
            {/* <FileUpload onFileChange={onFileChange} /> */}
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default CommonFields
