import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Tabs from 'react-responsive-tabs'
import Loader from 'react-loaders'

import { can, checkPermission, cms, editCMS } from '../helper'

import NoData from './PageVariation/noData'
import PageWithTabs from './PageVariation/pageWithTabs'
import ButtonMenu from './PageVariation/buttonMenu'
import HorizontalMenu from './PageVariation/horizonalMenu'
import { request } from '../Redux/Sagas/requests/api'
import { updatePageDataRedux } from '../Redux/Reducers/CMS'
import { useHistory } from 'react-router-dom'

const select = (state) => ({
  page: state.CMS.coreData.page,
})
export const renderBtnContent = (page = {}, action = {}, setBigDropdown, dropdownData, CMSData, isModalAction, fetchingPageData) => {
  if ((page.allowed && page.allowed === 0) || (!can(page.permission) && page.allowed !== 1)) {
    if (fetchingPageData) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Loader type='ball-pulse' color='blue' />
        </div>
      )
    } else {
      return <NoData message={page.not_allowed_message ? page.not_allowed_message : 'You do not have permission to see this.'} />
    }
  } else {
    switch (page.component_name) {
      case 'page_with_tabs':
        return (
          <PageWithTabs
            gridData={action}
            page={page}
            isModel={true}
            CMSData={CMSData}
            setBigDropdown={setBigDropdown}
            dropdownData={dropdownData}
            isModalAction={isModalAction}
          />
        )
      case 'button_menu':
        return <ButtonMenu page={page} isModel={true} CMSData={CMSData} setBigDropdown={setBigDropdown} />
      case 'horizontal_menu':
        return <HorizontalMenu page={page} isModel={true} CMSData={CMSData} setBigDropdown={setBigDropdown} />
      default:
        return <NoData />
    }
  }
}
const ActionCall = ({ isOpen, actions, page, CMSData, dropdownData = false, setBigDropdown = () => {} }, isModalAction) => {
  const [mainActions, setActions] = useState([])

  useEffect(() => {
    setActions(actions)
  }, [page, actions])

  if (!isOpen) return null

  return mainActions.map((action, key) => {
    return (
      <RenderAction
        key={key}
        index={key}
        page={page}
        action={action}
        setBigDropdown={setBigDropdown}
        dropdownData={dropdownData}
        CMSData={CMSData}
        isModalAction={isModalAction}
      />
    )
  })
}

const RenderAction = ({ action, setBigDropdown, dropdownData, CMSData, isModalAction, page, index }) => {
  // if (!page[action.page_name]) return <NoData key={key} />
  const [fetchingPageData, setFetchingPageData] = useState(false)
  const dispatch = useDispatch()
  const editMode = useSelector((state) => state.CMS.editMode)
  const history = useHistory()

  const getTabs = (pages) => {
    const tabs = []

    Object.keys(pages).map((key) => {
      const tab = pages[key]
      if (!checkPermission('view', pages[key]?.name, null, true)) return
      tabs.push({
        title: cms(tab.cms_header_name),
        cmsTabKey: tab.cms_header_name || key,
        getContent: () => renderBtnContent(tab, action, setBigDropdown, dropdownData, CMSData, isModalAction, fetchingPageData),
        key: key,
        tabClassName: 'tab',
        panelClassName: 'panel',
      })
    })
    return tabs
  }

  useEffect(() => {
    if (!page[action.page_name]) {
      // setLoadingPage(true)
      setFetchingPageData(true)
      if (action.page_name) {
        request(`get-page/${action.page_name}`, 'GET').then(({ data, status }) => {
          if (status === 401) {
            history.push('/logout')
          } else {
            let pageData = {}
            pageData[action?.page_name] = data

            if (data?.tab_near_to_pages?.length === 0 || !data?.tab_near_to_pages) {
              pageData[action?.page_name] = {
                ...pageData[action?.page_name],
                isRemovable: true,
                isMainPage: true,
              }
            }

            if (data?.tab_near_to_pages && JSON.stringify(data?.tab_near_to_pages) !== '[]') {
              if (Object.keys(data?.tab_near_to_pages)?.length > 0) {
                Object.keys(data?.tab_near_to_pages)?.map((item, index) => {
                  pageData[item] = {
                    ...data?.tab_near_to_pages[item],
                    isRemovable: true,
                  }
                })
              }
            }

            dispatch(updatePageDataRedux(pageData))
          }

          setFetchingPageData(false)
          // dispatch(setLoader(false))
          // setLoadingPage(false)
        })
      }
    }
  }, [action?.page_name, page[action.page_name]])

  if (fetchingPageData)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )
  if (dropdownData) {
    return (
      <div style={{ maxHeight: '90vh', overflow: 'scroll' }} key={index}>
        {renderBtnContent(page[action.page_name], action, setBigDropdown, dropdownData, CMSData, isModalAction, fetchingPageData)}
      </div>
    )
  } else {
    if (page[action.page_name]?.tab_near_to_pages) {
      const tabs = {
        [action.page_name]: page[action.page_name],
        ...page[action.page_name].tab_near_to_pages,
      }

      const handleTabEditCMS = (e) => {
        const tab = getTabs(tabs)?.filter((tab) => tab.key === e.nextTabKey)

        editCMS(tab?.[0]?.cmsTabKey)
        if (editMode) return false
      }

      if (getTabs(tabs)?.length === 0) return <div style={{ textAlign: 'center' }}>Content not available</div>
      return (
        <Tabs
          tabsWrapperClass={'body-tabs body-tabs-alt'}
          transform={false}
          showInkBar={true}
          items={getTabs(tabs)}
          beforeChange={(e) => handleTabEditCMS(e)}
        />
      )
    } else {
      return (
        <div key={index}>
          {renderBtnContent(page[action.page_name], action, setBigDropdown, dropdownData, CMSData, isModalAction, fetchingPageData)}
        </div>
      )
    }
  }
}

export default connect(select)(ActionCall)
